import React, { createContext, useState, useEffect } from 'react'
import {useNavigate } from "react-router-dom";

const CartContext = createContext();

export function CartProvider({ children }) {

  const navigate = useNavigate();

  function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


  const getCart = localStorage.getItem('cart') !== null && localStorage.getItem('cart') !== undefined  && localStorage.getItem('cart') !== '' && isJsonString(localStorage.getItem('cart'))  ? JSON.parse(localStorage.getItem('cart')) : [];
  const getMaxAmount = localStorage.getItem('MaxAmount') !== '' || localStorage.getItem('MaxAmount') !== null || localStorage.getItem('MaxAmount') !== undefined ?  localStorage.getItem('MaxAmount') : '';
  const getMinAmount = localStorage.getItem('MinAmount') !== '' || localStorage.getItem('MinAmount') !== null || localStorage.getItem('MinAmount') !== undefined ?  localStorage.getItem('MinAmount') : '';
  const getTime = localStorage.getItem('Time') !== '' || localStorage.getItem('Time') !== null ?  localStorage.getItem('Time') : '';





  const [Cart, setCart] = useState(getCart);
  const [Alert, setAlert] = useState('');
  const [MaxAmount, setMaxAmount] = useState(getMaxAmount);
  const [MinAmount, setMinAmount] = useState(getMinAmount);
  const [TimeCart, setTimeCart] = useState(getTime);


  useEffect(() => {
    window.addEventListener('storage', storageEventHandler ,false);

  }, []);

  function storageEventHandler(e) {
    // setMaxAmount(localStorage.getItem('MaxAmount'))
    // setMinAmount(localStorage.getItem('MinAmount'))
    // setTimeCart(getTime)
    // console.log(localStorage.getItem('MaxAmount'), localStorage.getItem('MinAmount'));
    
    if (e.key === "MaxAmount" ) {
      setMaxAmount(localStorage.getItem('MaxAmount'))
      console.log('changed local');
  
    }

    if (e.key === "MinAmount") {
   
      setMinAmount(localStorage.getItem('MinAmount'))
 
    }

    if (e.key === "Time") {
 
      setTimeCart(localStorage.getItem('Time'))
    }
    if (e.key === "cart") {
    setCart(localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : [])
    }
  }


  // useEffect(() => {
  //   localStorage.setItem('cart', JSON.stringify(Cart));
  //   // setCart(Cart)
  // }, [Cart]);
  
  useEffect(() => {
    
    console.log('changed MinAmount');
    localStorage.setItem('MinAmount', MinAmount);
    
    // setMaxAmount(MaxAmount)
    // setMinAmount(MinAmount)
    // setTimeCart(TimeCart)


  }, [MinAmount]);

  useEffect(() => {
    
    console.log('changed MaxAmount');
    

    localStorage.setItem('MaxAmount', MaxAmount);


    // setMaxAmount(MaxAmount)
    // setMinAmount(MinAmount)
    // setTimeCart(TimeCart)


  }, [MaxAmount]);

  useEffect(() => {
    
    console.log('changed Time');
    

   
    localStorage.setItem('Time', TimeCart);

    

    // setMaxAmount(MaxAmount)
    // setMinAmount(MinAmount)
    // setTimeCart(TimeCart)


  }, [TimeCart]);


  // console.log(MaxAmount, MinAmount , TimeCart);



  function CartTotal(Gift) {

    if (Gift === "Gift") {
      return Cart.filter(({free,family}) => free === false  && family !== "04").reduce((n, { quantité, price }) => n + (quantité * price), 0)
    }else{
      return Cart.filter(({free}) => free === false).reduce((n, { quantité, price }) => n + (quantité * price), 0)
    }
  }



  function GetGiftTotal(minOrPrice) {
    if (minOrPrice === "mincart") {
      return Cart.filter(({free}) => free === true).reduce((n, { quantité, mincart }) => n + (quantité * mincart), 0)
      
    }
    return Cart.filter(({free}) => free === true).reduce((n, { quantité, price }) => n + (quantité * price), 0)

  }

  function CheckOnlyGift() {

    const gift = Cart.filter(({free}) => free === true)
    const taksit = Cart.filter(({free,family}) => free === false && family !== "04" )
    const taksitWithoutGift = Cart.filter(({free,family}) => free === false && family == "04" )

    // console.log(taksit);

    return {"onlygift" : (gift.length > 0 && (taksit.length == 0 && taksitWithoutGift.length == 0)) , "onlytaksit": (gift.length == 0 && taksit.length > 0), "taksitplusgift" : (gift.length > 0 && (taksit.length > 0 || taksitWithoutGift.length > 0) ), "atleasttaksit": (taksit.length > 0 || taksitWithoutGift.length > 0) , "taksitWithoutGift" : (taksitWithoutGift.length > 0 && taksit.length == 0)}
    
  }


  function AddToCart(Product) {

    // if ((CartTotal() + (Product.price * Product.quantité)) > MaxAmount) {
    //   console.log((CartTotal() + Product.price) > MaxAmount);

    //   setAlert({ hash: Product.hash, page: 'Product', flag: 'danger ', msg: 'Le montant total maximum du panier ne peut être dépassé ' + MaxAmount?.toLocaleString() + ' DA' , msgar: 'لا يمكن تجاوز الحد الأقصى لمبلغ السلة الإجمالي ' + MaxAmount?.toLocaleString() + ' دجٍ'});
    //   return
    // }


    const found = Cart.find(element => element.hash == Product.hash);

    if (found == undefined) {

      setCart([...Cart, {
        "hash": Product.hash,
        "nom": Product.nom,
        "marque": Product.marque,
        "price": Product.price,
        "image": Product.image,
        "quantité": Product.quantité,
        "qtsUnite" : Product.qtsUnite,
        "free" : Product.free,
        "mincart" : Product.mincart,
        "family" : Product.family
      }])

      localStorage.setItem('cart', JSON.stringify([...Cart, {
        "hash": Product.hash,
        "nom": Product.nom,
        "marque": Product.marque,
        "price": Product.price,
        "image": Product.image,
        "quantité": Product.quantité,
        "qtsUnite" : Product.qtsUnite,
        "free" : Product.free,
        "mincart" : Product.mincart,
        "family" : Product.family
      }]));
      // setAlert({ hash: Product.hash, page: 'Product', flag: 'success', msg: 'Produit ajouté avec succès   ',msgar: 'تمت إضافة المنتج بنجاح. سلتي' });
      navigate('/cart', { replace: true })
    } else {
      setAlert({ hash: Product.hash, page: 'Product', flag: 'danger ', msg: 'le produit existe déjà dans le panier', msgar: 'المنتج موجود بالفعل في سلة التسوق' , ref: 1 });
    }

  }

  function CheckCarProduct(Producthash) {

     
    const found = Cart.find(element => element.hash == Producthash);

    if (found !== undefined) {
       return true
    }else{
      return false
    }

  }

  function RemoveFromCart(hash) {
    const tt = Cart.filter(word => word.hash != hash);
    // console.log(tt);
    setCart(Cart => Cart.filter(word => word.hash != hash))
    localStorage.setItem('cart', JSON.stringify(Cart.filter(word => word.hash != hash)));
    // setAlert({ page: 'Cart', flag: 'success', msg: 'Le produit a été retiré du panier', msgar: 'تمت إزالة المنتج من عربة التسوق' });
    // localStorage.setItem('cart', JSON.stringify(Cart));
  }

  const updateState = (hash, quantity,action) => {

    // if (action == 'min' || CartTotal() < MaxAmount) {

    // } else {
    //   return
    // }
    // console.log(CartTotal(), MaxAmount);

    const newState = Cart.map(obj => {
      if (obj.hash == hash) {
       
        if (quantity <= obj.qtsUnite ) {
          
          
          return { ...obj, quantité: quantity };
        }
      }
      return obj;
    });

    setCart(newState);
    localStorage.setItem('cart', JSON.stringify(newState))
    
  };

  return <CartContext.Provider value={{ Cart,setCart, AddToCart,CheckCarProduct, RemoveFromCart, updateState, Alert, setAlert, MaxAmount, setMaxAmount, MinAmount, setMinAmount, CartTotal,GetGiftTotal,CheckOnlyGift, TimeCart, setTimeCart }}>{children}</CartContext.Provider>
}


export default CartContext