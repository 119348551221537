import { useEffect, lazy, Suspense } from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import './App.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// import './components/HomePage/NavBar'
// import NavBar from './components/HomePage/NavBar';
// import Home from './pages/Home';
// import Dartaksit from './pages/Dartaksit'
// import Daridjar from './pages/Daridjar';
// import Dari3mar from './pages/Dari3mar';
// import Popup from './components/Global/Popup';
import { Provider } from './Context/bank';
import { LangProvider } from './Context/lang';
import { CartProvider } from './Context/cart';
// import Product from './pages/Product';
// import Dari3marProduct from './pages/DarI3mar/Product';
// import MyAccount from './pages/MyAccount';
// import Search from './pages/Search';
// import ShoppingCart from './pages/ShoppingCart';
import ScrollToTop from './ScrollToTop';
// import Simulation from './pages/Simulation';
// import Dashboard from './pages/Dashboard/Dashboard';
// import MesCommandes from './pages/Dashboard/MesCommandes';
// import CartSimulation from './pages/CartSimulation';
// import SimulationCart from './pages/SimulationCart';
// import CatProduct from './pages/CatProduct';
// import DaridjarDossier from './pages/DaridjarDossier';
// import Dari3marDossier from './pages/Dari3marDossier';
// import Validation from './pages/Validation';
// import Assistence from './pages/Assistence';
// import CategorieDarI3mar from './pages/CategorieDarI3mar';
// import DarSiyahaHome from './pages/DarSiyaha/DarSiyahaHome';
// import Inscription from './pages/DarIdjar/Inscription';
// import I3marSimulation from './components/DarI3mar/I3marSimulation';
// import DarSen3aHome from './pages/DarSen3a/DarSen3aHome';
// import Dar3a9arHome from './pages/Dar3a9ar/Dar3a9arHome';
// import DarTaksitDossier from './pages/DarTaksitDossier';
// import Faq from './pages/Faq';
import Call from './components/Global/Call';

// import Aide from './pages/Store/Aide';
// import HomeStore from './pages/Store/Home';
// import Boutique from './pages/Store/Boutique';
// import SearchPage from './pages/Store/SearchPage';
// import ConditionsGenerals from './pages/ConditionsGenerals';
// import SaidatyHome from './pages/Saidaty/SaidatyHome';
// import SimulationSaidaty from './pages/SimulationSaidaty';
// import ValidationSaidaty from './pages/ValidatioSaidaty';
// import DarAlmal from './pages/DarAlmal';
// import DarHiref from './pages/Darhiraf/DarHiref';
// import I3malHome from './pages/I3mal.js/I3malHome';
// import SimulationI3mal from './pages/SimulationI3mal';
// import ValidationI3mal from './pages/ValidationI3mal';
// import I3mal from './pages/I3malHome';
// import Moto from './pages/Moto';
// import { Layout } from './components/Global/Layout';
import CallSiyaha from './components/Siyaha/CallSiyaha';
import Trip from './pages/TripById';
import CallSpecial from './components/Global/CallSpecial';
import LoadingPage from './components/Global/LoadingPage';

// const HomeLazy = lazy(() => import('./pages/Home'));
// const DarTaksitLazy = lazy(() => import('./pages/Dartaksit'));
// const DarIdjarLazy = lazy(() => import('./pages/Daridjar'));
// const DarI3marLazy = lazy(() => import('./pages/Dari3mar'));
// const MotoLazy = lazy(() => import('./pages/Moto'));
// const ConditionsGeneralsLazy = lazy(() => import('./pages/ConditionsGenerals'));

// const SimulationLazy = lazy(() => import('./pages/Simulation'));


// const SimulationCartLazy = lazy(() => import('./pages/SimulationCart'));
// const ValidationLazy = lazy(() => import('./pages/Validation'));

// const SimulationSaidatyLazy = lazy(() => import('./pages/SimulationSaidaty'));

// const ValidationSaidatyLazy = lazy(() => import('./pages/ValidatioSaidaty'));

// const MeublesLazy = lazy(() => import('./pages/Meubles'));
// const ElectroMenagerLazy = lazy(() => import('./pages/ElectroMenager'));

// const ChauffagesLazy = lazy(() => import('./pages/Chauffages'));
const SiyahaLazy = lazy(() => import('./pages/Siyaha'));
const ProductSiyahaLazy = lazy(() => import('./pages/ProductSiyaha'));

// const AutoLazy = lazy(() => import('./pages/Auto'));





const queryClient = new QueryClient()





function App() {

  const location = useLocation();
  // console.log('pathname', location.pathname);


  // const getBank = localStorage.getItem('bank');
  // const getBank = 'bna';



  // console.log(location.pathname.split('/')[1]);

  useEffect(() => {
    const dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-5DJPJ9DWE3');

  }, [])



  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>

        <LangProvider>
          <CartProvider>
            <Provider>
              {/* {location.pathname.split('/')[1] !== "store" && location.pathname.split('/')[1] !== "boutique" && location.pathname !== "/aide" && location.pathname !== "/i3mal" && location.pathname !== "/moto" && location.pathname !== "/i3malhome" && location.pathname.split('/')[1] !== "i3malform" && location.pathname.split('/')[1] !== "validationi3mal" && location.pathname !== "/store" && location.pathname !== "/account" && location.pathname.split('/')[1] !== "simulation" && (location.pathname.split('/')[1] + "/" + location.pathname.split('/')[2]) !== "daridjar/inscription" && location.pathname.split('/')[1] !== "validation" && location.pathname !== "/simulationcart" && location.pathname !== "/profil" && location.pathname !== "/mescommandes" && location.pathname !== "/saidaty" && location.pathname.split('/')[1] !== "saidatyform" && location.pathname.split('/')[1] !== "validationsaidaty" && location.pathname.split('/')[1] !== "darsiyaha" && location.pathname.split('/')[1] !== "darhiref" ? <NavBar Section={location.pathname} /> : ''} */}

              {/* {getBank == null ? <Popup /> : ''} */}

              <ScrollToTop>
                {
                  location.pathname !== "/" && location.pathname.split('/')[1] !== "trip" ?
                    <Call /> : ''
                }
                {location.pathname == "/" || location.pathname.split('/')[1] == "trip" ?
                  <>
                    <CallSiyaha />
                    <a href="https://www.facebook.com/RookaTravel?mibextid=LQQJ4d" target='_blank'>
                      <div className="Chat d-none d-lg-flex">
                        <ul>
                          <li className="facebook">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.755 18.351">
                              <path id="Tracé_9347" data-name="Tracé 9347" d="M49.991,358.027l.506-3.3H47.335v-2.139a1.647,1.647,0,0,1,1.858-1.78h1.438v-2.806a17.515,17.515,0,0,0-2.553-.223c-2.6,0-4.307,1.579-4.307,4.436v2.512h-2.9v3.3h2.9v7.968a11.492,11.492,0,0,0,3.564,0v-7.968Z" transform="translate(-40.876 -347.783)" fill="#fff" />
                            </svg>

                          </li>
                        </ul>
                      </div></a>
                  </> : ''}

                  {location.pathname == "/meubles" || location.pathname == "/electromenager" ?
                  
                    <CallSpecial tel="0982 316 298" />
                    
                 
                  : ''}

                <Routes>

                  {/* <Route path="/" element={<Layout />}> */}
                    <Route path="*" element={<SiyahaLazy />} />
                    <Route index element={<SiyahaLazy />} />
                    {/* Dar Idjar */}
                    {/* <Route path="/daridjar" element={<DarIdjarLazy />} />
                    <Route path="/daridjar/dossier" element={<DaridjarDossier />} />
                    <Route path="/daridjar/inscription/:hash" element={<Inscription />} /> */}

                    {/* Dar Idjar */}

                    {/* Dar I3mar */}
                    {/* <Route path="/dari3mar" element={<DarI3marLazy />} />
                    <Route path="/dari3mar/dossier" element={<Dari3marDossier />} />
                    <Route path="/dari3mar/categorie/:id" element={<CategorieDarI3mar />} />
                    <Route path="/dari3mar/product/:id" element={<Dari3marProduct />} />
                    <Route path="/dari3mar/simulation/:id" element={<I3marSimulation />} /> */}
                    {/* Dar I3mar */}


                    {/* <Route path='/assistence' element={<Assistence />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path='/conditionsgenerales' element={<ConditionsGeneralsLazy />} /> */}

                    {/* <Route path='/dartaksit/dossier' element={<DarTaksitDossier />} /> */}



                    {/* <Route path='/daralmal' element={<DarAlmal />} /> */}

                    {/* Dar Sen3a */}
                    {/* <Route path="/darsan3a" element={<DarSen3aHome />} /> */}
                    {/* Dar Sen3a */}

                    {/* Dar 3a9ar */}
                    {/* <Route path="/dar3a9ar" element={<Dar3a9arHome />} /> */}
                    {/* Dar 3a9ar */}

                    {/* <Route path="/dartaksit" element={<DarTaksitLazy />} /> */}
                    {/* <Route path="/cat/:catname" element={<CatProduct />} />
                    <Route path="/cat" element={<CatProduct />} />
                    <Route path="/product/:id" element={<Product />} /> */}
                    <Route path="/trip/:vente" element={<Trip />} />
                    {/* <Route path="/search/:keywords" element={<Search />} />
                    <Route path="/search/" element={<Search />} />
                    <Route path="/cart" element={<ShoppingCart />} /> */}

                  {/* </Route> */}




                  {/* Dar Siayaha */}
                  {/* <Route path="/darsiyaha" element={<DarSiyahaHome />} /> */}
                  {/* Dar Siayaha */}


                  {/* Dar Hiref */}
                  {/* <Route path="/darhiref" element={<DarHiref />} /> */}
                  {/* Dar Hiref */}



                  {/* Dar Store */}
                  {/* <Route path="/aide" element={<Aide />} />
                <Route path="/Store" element={<HomeStore />} />
                <Route path="/boutique/:slug" element={<Boutique />} /> */}
                  {/* <Route path="/boutique" element={<Boutique />} /> */}
                  {/* <Route path="/store/search" element={<SearchPage />} /> */}

                  {/* Dar Store */}


                  {/* <Route path="/account" element={<MyAccount />} />
                  <Route path="/simulation/:hash" element={<Suspense fallback={<LoadingPage />}><SimulationLazy /></Suspense>} />
                  <Route path="/simulationcart/:hash" element={<Suspense fallback={<LoadingPage />}> <SimulationCartLazy /></Suspense>} />
                  <Route path="/cartsimulation" element={<CartSimulation />} />
                  <Route path="/validation/:hash" element={<Suspense fallback={<LoadingPage />}><ValidationLazy /></Suspense>} /> */}


{/* 
                  <Route path="/profil" element={<Dashboard />} />
                  <Route path='/mescommandes' element={<MesCommandes />} /> */}


{/* 
                  <Route path='/saidaty' element={<SaidatyHome />} />
                  <Route path='/saidatyform/:hash' element={<Suspense fallback={<LoadingPage />}><SimulationSaidatyLazy /></Suspense>} />
                  <Route path='/validationsaidaty/:hash' element={<Suspense fallback={<LoadingPage />}><ValidationSaidatyLazy /></Suspense>} /> */}



                  {/* <Route path='/i3malhome' element={< I3mal />} />
                <Route path='/i3mal' element={< I3malHome />} />
                <Route path='/i3malform/:hash' element={<SimulationI3mal />} />
                <Route path='/validationi3mal/:hash' element={<ValidationI3mal />} /> */}

                  {/* <Route path='/moto' element={<Suspense fallback={<LoadingPage />}>< MotoLazy /></Suspense>} />
                  <Route path='/meubles' element={<Suspense fallback={<LoadingPage />}>< MeublesLazy /></Suspense>} />
                  <Route path='/electromenager' element={<Suspense fallback={<LoadingPage />}>< ElectroMenagerLazy /></Suspense>} />

                  <Route path='/chauffages' element={<Suspense fallback={<LoadingPage />}><ChauffagesLazy /></Suspense>} /> */}


                  <Route path='/siyaha' element={<Suspense fallback={<LoadingPage />}>< SiyahaLazy /></Suspense>} />
                  <Route path='/siyaha/:id' element={<Suspense fallback={<LoadingPage />}>< ProductSiyahaLazy /></Suspense>} />


                  {/* <Route path='/auto' element={<Suspense fallback={<LoadingPage />}>< AutoLazy /></Suspense>} /> */}


















                </Routes>
              </ScrollToTop>
            </Provider>
          </CartProvider>
        </LangProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
