import {useContext } from "react"
// import {useNavigate} from "react-router-dom";
import BankContext from '../../Context/bank'

const LoadingPage = () => {

  const { bankActive, setBankActive } = useContext(BankContext);

//   const navigate = useNavigate();

    return (

        <div className={`centered-logo`}>
            <img className='' alt="logodiardzair" src={`/img/${bankActive == 'alsalam' ? "logo-fr.svg" : "logo-bna.svg"}`} width="300" />
            <div className="loadingPage-text">جاري التحميل</div>
        </div>

    )
}

export default LoadingPage