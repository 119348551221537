import {useContext } from "react"
import {useNavigate} from "react-router-dom";
import BankContext from '../../Context/bank'

const Call = () => {

  const { bankActive, setBankActive } = useContext(BankContext);

  const navigate = useNavigate();

    return (

        <div className={`Callus d-none d-lg-flex`} onClick={()=> navigate('/assistence')}>
            <ul>
                <li className={`top-bar-${bankActive} facebook`}>
                    <svg xmlns="https://www.w3.org/2000/svg" width="70.236" height="70.221" viewBox="0 0 70.236 70.221">
                        <g id="headset" transform="translate(-105.9 -229.479)">
                            <path id="Tracé_205" data-name="Tracé 205" d="M105.918,267.063c.11-.458.2-.934.311-1.392a10.863,10.863,0,0,1,4.652-6.538.955.955,0,0,0,.531-.916,29.6,29.6,0,0,1,57.269-9.615,2.752,2.752,0,1,1-5.128,1.96,23.824,23.824,0,0,0-17.161-14.945,24.1,24.1,0,0,0-29.12,19.029c-.147.842-.238,1.7-.385,2.656h3.223c.733,0,1.465-.018,2.2,0a2.709,2.709,0,0,1,2.765,2.729q.027,12.912,0,25.842a2.6,2.6,0,0,1-2.454,2.656,59.852,59.852,0,0,1-7.252-.11,10.763,10.763,0,0,1-9.34-9.139,3.978,3.978,0,0,0-.128-.44C105.918,274.939,105.918,271,105.918,267.063Zm13.663,16.1V262.833c-1.1,0-2.143-.073-3.187.018a5.429,5.429,0,0,0-4.982,5.275c-.037,3.223-.037,6.447,0,9.652a5.334,5.334,0,0,0,4.432,5.183A31.081,31.081,0,0,0,119.581,283.162Z" transform="translate(0 0)" fill="#fff" />
                            <path id="Tracé_206" data-name="Tracé 206" d="M272.352,424c-.293-.092-.6-.183-.9-.275a5.484,5.484,0,0,1-.018-10.4,5.379,5.379,0,0,1,6.1,1.923,1.176,1.176,0,0,0,1.117.549c2.491-.018,4.982,0,7.472-.018a2.741,2.741,0,0,0,2.949-2.949V384.88c0-2.2,1.026-3.242,3.223-3.223a65.539,65.539,0,0,1,6.575.147,10.8,10.8,0,0,1,9.377,10.366c.073,3.406.073,6.813,0,10.2a10.951,10.951,0,0,1-10.586,10.512c-1.007.018-2,0-3,0-.128.733-.183,1.429-.366,2.106a8.167,8.167,0,0,1-7.93,6.245q-3.9.027-7.8,0a1,1,0,0,0-.952.458A5.88,5.88,0,0,1,274,423.981C273.451,424,272.9,424,272.352,424ZM294.6,407.406c1.007,0,1.941.018,2.875,0a5.463,5.463,0,0,0,5.311-5.311c.037-3.223.037-6.447,0-9.652a5.4,5.4,0,0,0-3.645-4.982c-1.483-.513-3-.256-4.542-.293Z" transform="translate(-132.167 -124.3)" fill="#fff" />
                        </g>
                    </svg>

                    <div className="slider">
                        <p>0982 300 500</p>
                    </div>
                </li>
            </ul>
        </div>

    )
}

export default Call